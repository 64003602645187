import React, { Suspense } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { SnackbarProvider } from "notistack";
import "./style.css";
import routes, { routesPWA } from "./routes";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import RouteScrollTop from "./utils/RouteScrollTop";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// react slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRoutes } from "react-router-dom";
// import UploadFolding from "./Components/InStoreAdmin/Archive/ArchivePhotoGallery/UploadFolding";
// import { useSelector } from "react-redux";

function App() {
	const content = useRoutes(routes);
	const contentPWA = useRoutes(routesPWA);

	TopBarProgress.config({
		barColors: {
			0: "#7CCCF2",
			"1.0": "#23A8E8",
		},
		shadowBlur: 5,
	});

	// const restArchive = useSelector((state) => state.archive.restArchive);

	return (
		<SnackbarProvider
			maxSnack={1}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<RouteScrollTop />
			{/* <UploadFolding
				resArchive={restArchive}
				//  refetch={refetch}
			/> */}
			<Suspense fallback={<TopBarProgress />}>
				{window.matchMedia("(display-mode: standalone)").matches ? contentPWA : content}
			</Suspense>
		</SnackbarProvider>
	);
}

export default App;
