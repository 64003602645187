import useAuth from "../hooks/auth.hook";
import { useLocation } from "react-router-dom";
import { UserType } from "../auth.utils";
import { ROUTING_TREE } from "../../constants/siteUrls";
import { useEffect, useState } from "react";

const AuthProvider = ({
	children,
	roles, // array of roles or single role -> UserType i.e., [UserType.SUPER_ADMIN, UserType.COMPANY_ADMIN] or UserType.SUPER_ADMIN
	super_admin, // Super admin component
	company_admin, // Company admin component
	branch_admin, // Branch admin component
	branch_user, // Branch user component
	photographer, // Photographer component
	user, // User component
}) => {
	const { me, navigate } = useAuth();
	const location = useLocation();

	const [loader, setLoader] = useState(false);

	useEffect(() => {
		// Check route needs role but no logged in user
		if (roles && !me) {
			setLoader(true);
		}

		// Check already logged in but trying login route
		if (me && location.pathname === `/${ROUTING_TREE.AUTH.AUTH}/${ROUTING_TREE.AUTH.LOGIN}`) {
			setLoader(false);
			navigate(ROUTING_TREE.HOME.HOME);
			return true;
		}

		if (
			me &&
			(location.pathname ===
				`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN}` ||
				location.pathname ===
					`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.SELLING_PAGE_LOGIN}`)
		) {
			setLoader(false);
			navigate(`/${ROUTING_TREE.IN_STORE_ADMIN.IN_STORE}/${ROUTING_TREE.IN_STORE_ADMIN.DASHBOARD}`);
			return true;
		}

		// Check route needs role and if user has it
		if (me && roles) {
			setLoader(false);
			if (Array.isArray(roles)) {
				if (!roles.includes(me.user_type)) navigate(ROUTING_TREE.HOME.HOME);
			} else {
				if (roles !== me.user_type) navigate(ROUTING_TREE.HOME.HOME);
			}
			return true;
		}
	}, [location, me, navigate, roles]);

	useEffect(() => {
		let timer = null;

		if (loader)
			timer = setTimeout(() => {
				if (location.pathname.includes("/super") || location.pathname.includes("/company")) {
					navigate(ROUTING_TREE.HOME.HOME);
				} else if (location.pathname.includes("/in-store")) {
					navigate(`/${ROUTING_TREE.IN_STORE_AUTH.AUTH}/${ROUTING_TREE.IN_STORE_AUTH.BRANCH_ADMIN_LOGIN}`);
				} else {
					navigate(ROUTING_TREE.HOME.HOME);
				}
			}, 500);

		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loader]);

	// Check super admin specific component
	if (me && me.user_type === UserType.SUPER_ADMIN) {
		if (super_admin) return super_admin;
		else return children;
	}

	// Check company admin specific component
	if (me && me.user_type === UserType.COMPANY_ADMIN) {
		if (company_admin) return company_admin;
		else return children;
	}

	// Check branch admin specific component
	if (me && me.user_type === UserType.BRANCH_ADMIN) {
		if (branch_admin) return branch_admin;
		else return children;
	}

	// Check branch user specific component
	if (me && me.user_type === UserType.BRANCH_USER) {
		if (branch_user) return branch_user;
		else return children;
	}

	// Check photographer specific component
	if (me && me.user_type === UserType.PHOTOGRAPHER) {
		if (photographer) return photographer;
		else return children;
	}

	// Check user specific component
	if (me && me.user_type === UserType.USER) {
		if (user) return user;
		else return children;
	}

	return children;
};

export default AuthProvider;
