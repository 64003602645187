import { emptySplitApi } from "./base";

export const orderApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		filteredOrders: build.query({
			query: (params) => ({
				url: "/order/filter",
				params,
			}),
		}),
		filteredPhotographerOrders: build.query({
			query: (params) => ({
				url: "/order/filter-photographer",
				params,
			}),
		}),
		submitOrder: build.mutation({
			query: (body) => ({
				url: "/order/",
				method: "POST",
				body,
			}),
		}),
		submitOrderPayment: build.mutation({
			query: (body) => ({
				url: "/order/payment",
				method: "POST",
				body,
			}),
		}),
		submitOrderWithUser: build.mutation({
			query: (body) => ({
				url: "order/order_with_user",
				method: "POST",
				body,
			}),
		}),
		updateOrder: build.mutation({
			query: ({ id, body }) => ({
				url: `/order/${id}`,
				method: "PUT",
				body,
			}),
		}),
		setOfferRestPhotos: build.mutation({
			query: (body) => ({
				url: "/order/offer_rest_photos",
				method: "POST",
				body,
			}),
		}),
		getOfferByUser: build.query({
			query: ({ user_id }) => ({
				url: `/order/get_offer_by_user/${user_id}`,
			}),
		}),
		deleteOrder: build.mutation({
			query: (id) => ({
				url: `/order/${id}`,
				method: "DELETE",
			}),
		}),
		getOrderById: build.query({
			query: (id) => ({
				url: `/order/${id}`,
			}),
		}),
		sendDownloadLink: build.mutation({
			query: (body) => ({
				url: "/order/send_download_link",
				method: "POST",
				body,
			}),
		}),
	}),
});

export const {
	useFilteredOrdersQuery,
	useFilteredPhotographerOrdersQuery,
	useSubmitOrderMutation,
	useSubmitOrderPaymentMutation,
	useSubmitOrderWithUserMutation,
	useUpdateOrderMutation,
	useSetOfferRestPhotosMutation,
	useGetOfferByUserQuery,
	useDeleteOrderMutation,
	useGetOrderByIdQuery,
	useSendDownloadLinkMutation,
} = orderApi;
