import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API,
		prepareHeaders: (headers, { getState }) => {
			const { auth } = getState();
			const tokenCookie = Cookies.get("access_token");
			const token = auth.access_token ?? tokenCookie;

			if (token) headers.set("authorization", `Bearer ${sessionStorage.getItem("access_token")}`);

			return headers;
		},
	}),
	endpoints: () => ({}),
	// Handle token expiration in onError
	onError: (error) => {
		console.log("redux error", error);
		if (error.status === 200) {
			// Token expired
			// Handle token expiration here, for example, by dispatching an action
			console.log("Token expired");
		}
	},
});
