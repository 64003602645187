import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
import Images from "../../../../constants/themeData";
import { useStyles } from "./styled";
import { Link, NavLink } from "react-router-dom";
import { ROUTING_TREE } from "../../../../constants/siteUrls";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useWindowScroll } from "react-use";
// import EnDropdown from "./EnDropdown";
import AppsDropdown from "./AppsDropdown";
import MobileMenu from "./MobileMenu";
import SignUp from "../SignUp";
import { NavHashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../../../../utils/helper/scrollWithOffset";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = (props) => {
	const classes = useStyles();
	const { y: pageYOffset } = useWindowScroll();
	const [bgColor, setBgColor] = React.useState(false);

	// background changer
	React.useEffect(() => {
		if (pageYOffset > 30) {
			setBgColor(true);
		} else {
			setBgColor(false);
		}
	}, [pageYOffset]);

	// mobile menu toggler
	const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

	const handleToggleMobileMenuOpen = (open) => {
		setMobileMenuOpen(open);
	};

	// Signup dialog
	const [signUpdDialogOpen, setSignUpdDialogOpen] = React.useState(false);

	const handleSignUpDialogOpen = () => {
		setSignUpdDialogOpen(true);
	};

	const handleSignUpDialogClose = () => {
		setSignUpdDialogOpen(false);
	};

	return (
		<AppBar
			position="fixed"
			className={bgColor ? classes.appBarWhite : classes.appBarTransparent}
			sx={{ boxShadow: "0" }}
		>
			<Container maxWidth="lg">
				<Toolbar disableGutters>
					{/* logo in large device*/}
					<Box
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex", flexGrow: 1 },
						}}
					>
						<Link to={ROUTING_TREE.HOME.HOME}>
							<Avatar
								className={classes.logoAvatar}
								src={bgColor ? Images.NavLogo : Images.NavLogoWhite}
							/>
						</Link>
					</Box>

					{/* logo in small device */}
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
						}}
					>
						<Link to={ROUTING_TREE.HOME.HOME}>
							<Avatar
								className={classes.logoAvatar}
								src={bgColor ? Images.NavLogo : Images.NavLogoWhite}
							/>
						</Link>
					</Box>

					{/* menu in large device*/}
					<Box
						sx={{
							flexGrow: 0,
							display: { xs: "none", md: "flex" },
						}}
					>
						<NavHashLink
							to="#home"
							smooth
							// activeClassName="selected"
							className={((navData) => (navData.isActive ? "selected" : "none"), classes.link)}
							// className={classes.link}
						>
							<Button
								sx={{
									color: bgColor ? "text.primary" : "common.white",
								}}
								className={classes.navBtn}
							>
								Home
							</Button>
						</NavHashLink>

						<NavHashLink
							to="#benefits"
							smooth
							// activeClassName="selected"
							className={((navData) => (navData.isActive ? "selected" : "none"), classes.link)}
							scroll={(el) => scrollWithOffset(el, 50)}
							// className={classes.link}
						>
							<Button
								sx={{
									color: bgColor ? "text.primary" : "common.white",
								}}
								className={classes.navBtn}
							>
								Benefits
							</Button>
						</NavHashLink>
						<NavHashLink
							to="#work"
							smooth
							// activeClassName="selected"
							className={((navData) => (navData.isActive ? "selected" : "none"), classes.link)}
							scroll={(el) => scrollWithOffset(el, 50)}
							// className={classes.link}
						>
							<Button
								sx={{
									color: bgColor ? "text.primary" : "common.white",
								}}
								className={classes.navBtn}
							>
								How It Work
							</Button>
						</NavHashLink>
						{/* menu with dropdown */}
						<NavHashLink
							to="#apps"
							smooth
							// activeClassName="selected"
							className={((navData) => (navData.isActive ? "selected" : "none"), classes.link)}
							scroll={(el) => scrollWithOffset(el, 50)}
							// className={classes.link}
						>
							<Button
								className={`${classes.navBtn}, ${classes.dropdown}`}
								sx={{
									color: bgColor ? "text.primary" : "common.white",
									textTransform: "capitalize",
								}}
							>
								Apps
								<ArrowDropDownIcon />
								<AppsDropdown />
							</Button>
						</NavHashLink>
						<NavHashLink
							to="#pricing"
							smooth
							// activeClassName="selected"
							className={((navData) => (navData.isActive ? "selected" : "none"), classes.link)}
							scroll={(el) => scrollWithOffset(el, 50)}
							// className={classes.link}
						>
							<Button
								sx={{
									color: bgColor ? "text.primary" : "common.white",
								}}
								className={classes.navBtn}
							>
								Pricing
							</Button>
						</NavHashLink>
						<Button
							sx={{
								color: bgColor ? "text.primary" : "common.white",
							}}
							className={classes.navBtn}
							onClick={handleSignUpDialogOpen}
						>
							Register
						</Button>
						{/* menu with dropdown */}
						{/* <Button
							sx={{
								color: bgColor ? "text.primary" : "common.white",
							}}
							className={`${classes.navBtn}, ${classes.enDropdown}`}
						>
							En
							<ArrowDropDownIcon />
							<EnDropdown />
						</Button> */}
						<NavLink to={ROUTING_TREE.HOME.SHOP} className={classes.link}>
							<Button
								sx={{
									color: bgColor ? "text.primary" : "common.white",
								}}
								className={classes.navBtn}
							>
								Shop
							</Button>
						</NavLink>
					</Box>

					{/* menu in small device*/}
					<Box
						sx={{
							flexGrow: 0,
							display: { xs: "flex", md: "none" },
						}}
						className={classes.menuWrapper}
					>
						<IconButton
							onClick={() => handleToggleMobileMenuOpen(true)}
							sx={{ color: bgColor ? "#5f6368" : "common.white" }}
						>
							<MenuIcon fontSize="large" />
						</IconButton>

						<MobileMenu
							mobileMenuOpen={mobileMenuOpen}
							handleToggleMobileMenuOpen={handleToggleMobileMenuOpen}
							handleSignUpDialogOpen={handleSignUpDialogOpen}
						/>
					</Box>
				</Toolbar>
			</Container>

			{/* Sign up */}
			<SignUp signUpdDialogOpen={signUpdDialogOpen} handleSignUpDialogClose={handleSignUpDialogClose} />
		</AppBar>
	);
};

export default Navbar;
