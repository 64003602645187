import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import { useGetOrderByIdQuery } from "../../store/api/order.api";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from "axios";

const DownloadPhoto = () => {
	const [searchParams] = useSearchParams();
	const orderId = searchParams.get("orderId");

	const [isFinished, setIsFinished] = React.useState(false);

	const { data: order, error } = useGetOrderByIdQuery(orderId);

	useEffect(() => {
		if (order && order?.data.products.length > 0) {
			const zip = new JSZip();
			var count = 0;

			let arrFolder = [];

			order?.data.products.forEach((item) => {
				arrFolder[item.format] = zip.folder(item.format);
			});

			order?.data.products.forEach(function (item) {
				var filename = "qty_" + item.quantity + "_" + item.file_url.replace(/.*\//g, "");
				axios
					.get(item.file_url, {
						responseType: "blob",
					})
					.then((response) => {
						arrFolder[item.format].file(filename, response.data, {
							binary: true,
						});

						++count;

						if (count === order?.data.products.length) {
							zip.generateAsync({
								type: "blob",
							}).then(function (content) {
								saveAs(content, order.data.purchaser_passcode + ".zip");
								setIsFinished(true);
							});
						}
					})
					.catch((error) => {
						console.log(error);
					});
			});
		}
	}, [order]);

	const downloadSuccess = () => {
		return (
			<>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
					<SvgIcon className="w4rAnimated_checkmark" sx={{ height: 100, width: 100 }}>
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
							<circle
								className="path circle"
								fill="none"
								stroke="#1ea5e9"
								strokeWidth="6"
								strokeMiterlimit="10"
								cx="65.1"
								cy="65.1"
								r="62.1"
							/>
							<polyline
								className="path check"
								fill="none"
								stroke="#1ea5e9"
								strokeWidth="6"
								strokeLinecap="round"
								strokeMiterlimit="10"
								points="100.2,40.2 51.5,88.8 29.8,67.5 "
							/>
						</svg>
					</SvgIcon>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
					<Typography variant="h5">Your download completed!</Typography>
				</Box>
			</>
		);
	};

	const downloadError = () => {
		return (
			<>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
					<SvgIcon className="w4rAnimated_checkmark" sx={{ height: 100, width: 100 }}>
						<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm5.793-4.207a1 1 0 0 1 1.414 0L12 10.586l2.793-2.793a1 1 0 1 1 1.414 1.414L13.414 12l2.793 2.793a1 1 0 0 1-1.414 1.414L12 13.414l-2.793 2.793a1 1 0 0 1-1.414-1.414L10.586 12 7.793 9.207a1 1 0 0 1 0-1.414z"
								fill="#C73E1D"
							/>
						</svg>
					</SvgIcon>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
					<Typography variant="h5">Your order not found</Typography>
				</Box>
			</>
		);
	};

	const downloadOnProgress = () => {
		return (
			<>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 5 }}>
					<SvgIcon className="w4rAnimated_checkmark" sx={{ height: 100, width: 100 }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="48"
							height="48"
							fill="currentColor"
							class="bi bi-download"
							viewBox="0 0 36 36"
						>
							{" "}
							<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />{" "}
							<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />{" "}
						</svg>
					</SvgIcon>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 3 }}>
					<Typography variant="h5">Download in progress</Typography>
				</Box>
			</>
		);
	};

	return error ? downloadError() : isFinished ? downloadSuccess() : downloadOnProgress();
};

export default DownloadPhoto;
