import { configureStore } from "@reduxjs/toolkit";
import bookingSlice from "./slices/booking.slice";
import settingsSlice from "./slices/settings.slice";
import authSlice from "./slices/auth.slice";
import companySlice from "./slices/company.slice";
import branchSlice from "./slices/branch.slice";
import searchSlice from "./slices/search.slice";
import notificationSlice from "./slices/notification.slice";
import selectedImageSlice from "./slices/selectedImage.slice";
import packageSlice from "./slices/package.slice";
import contactSlice from "./slices/contact.slice";
import totalSlice from "./slices/total.slice";
import archiveSlice from "./slices/archive.slice";
import onlineStoreSlice from "./slices/onlineStore.slice";
import smtpSlice from "./slices/smtp.slice";
import activitySlice from "./slices/activity.slice";
import photographerSlice from "./slices/photographer.slice";
import customerSlice from "./slices/customer.slice";
import { emptySplitApi } from "./api/base";

export const store = configureStore({
	reducer: {
		settings: settingsSlice,
		booking: bookingSlice,
		auth: authSlice,
		company: companySlice,
		branch: branchSlice,
		search: searchSlice,
		notification: notificationSlice,
		selectedImage: selectedImageSlice,
		packages: packageSlice,
		contact: contactSlice,
		total: totalSlice,
		archive: archiveSlice,
		onlineStore: onlineStoreSlice,
		smtp: smtpSlice,
		activity: activitySlice,
		photographer: photographerSlice,
		customer: customerSlice,

		[emptySplitApi.reducerPath]: emptySplitApi.reducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(emptySplitApi.middleware),
});
