// bgColor for icon
export const iconStyles = {
	bgcolor: "#F3F3F3",
};

export const iconColor = {
	color: "#5f6368",
};

// paperprops
export const paperPropsStyles = {
	overflow: "visible",
	filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
	mt: 1.5,
	"& .MuiAvatar-root": {
		width: 32,
		height: 32,
		ml: -0.5,
		mr: 1,
	},
	"&:before": {
		content: '""',
		display: "block",
		position: "absolute",
		top: 0,
		right: 14,
		width: 10,
		height: 10,
		bgcolor: "background.paper",
		transform: "translateY(-50%) rotate(45deg)",
		zIndex: 0,
	},
};

// select input styles
export const selectFormElementStyles = {
	"& .MuiInput-root": {
		"&::before": {
			borderBottom: "none !important",
		},
		"&::after": {
			borderBottom: "none !important",
		},
	},
};

// navbar/ app bar styles
export const navStyles = {
	bgcolor: "common.white",
	color: "text.secondary",
	boxShadow: "rgb(43 83 135 / 30%) 0px 3px 8px 0px !important",
};
