import { createSlice } from "@reduxjs/toolkit";
import { bookingStatus } from "../../utils/booking.utils";

const initialState = {
	search: "",
	booking: {
		name: "",
		email: "",
		room_no: null,
		checkout_date: "",
		booking_date: "",
		phone_number: "",
		company_id: "",
		branch_id: "",
		time: "",
		notice: "",
		status: bookingStatus.pending,
		schedule_timing: 0,
	},
	branch_id: "",
};

export const bookingSlice = createSlice({
	name: "booking",
	initialState,
	reducers: {
		updateBookingForm: (state, action) => {
			state.booking[action.payload.field] = action.payload.value;
		},

		resetBookingForm: (state) => {
			state.booking = initialState.booking;
		},
		setBranchId: (state, action) => {
			state.branch_id = action.payload;
		},
		bookingSearch: (state, action) => {
			state.search = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateBookingForm, resetBookingForm, setBranchId, bookingSearch } = bookingSlice.actions;

export default bookingSlice.reducer;
