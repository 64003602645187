import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
	<Router>
		<React.StrictMode>
			<HelmetProvider>
				<CookiesProvider>
					<Provider store={store}>
						<App />
					</Provider>
				</CookiesProvider>
			</HelmetProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById("root"),
);

serviceWorkerRegistration.register();
