import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	search: "",
};

export const photographerSlice = createSlice({
	name: "photographer",
	initialState,
	reducers: {
		photographerSearch: (state, action) => {
			state.search = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { photographerSearch } = photographerSlice.actions;

export default photographerSlice.reducer;
