export const ROUTING_TREE = {
	HOME: {
		HOME: "/",
		BENEFITS: "benefits",
		HOW_IT_WORKS: "how-it-work",
		APPS: "apps",
		PRICING: "pricing",
		REGISTER: "register",
		SHOP: "shop",
	},
	AUTH: {
		AUTH: "auth",
		LOGIN: "login",
		FORGET_PASSWORD: "forget-password",
		RESET_TOKEN: "reset/:token",
		VERIFY_TOKEN: "verify/:token",
	},
	IN_STORE_AUTH: {
		AUTH: "in-store",
		SELLING_PAGE_LOGIN: "login",
		BRANCH_ADMIN_LOGIN: "admin/login",
		FORGET_PASSWORD: "forget-password",
		RESET_TOKEN: "reset/:token",
		VERIFY_TOKEN: "verify/:token",
	},
	BOOKINGS: {
		BOOKINGS: "bookings",
		BOOKINGS_BY_BRANCH_ID: "branch/:branch_id",
		DETAILS: "details",
		SCHEDULE: "schedule",
		CONGRATULATIONS: "congratulations",
		LISTS: "lists",
	},
	SPA_BOOKINGS: {
		BOOKINGS: "spa-bookings",
		BOOKINGS_BY_BRANCH_ID: "branch/:branch_id",
		DETAILS: "details",
		SCHEDULE: "schedule",
		CONGRATULATIONS: "congratulations",
		LISTS: "lists",
	},
	SUPER_ADMIN: {
		SUPER: "super",
		DASHBOARD: "dashboard",
		COMPANIES: "companies",
		COMPANY_REQUEST: "company-request",
		E_COMMERCE: "e-commerce",
		E_WALLET: "e-wallet",
		COUPONS: "coupons",
		CURRENCY: "currency",
		PRICING: "pricing",
		EMAIL_BANK: "email-bank",
		CMS: "cms",
	},
	COMPANY_ADMIN: {
		COMPANY: "company",
		DASHBOARD: "dashboard",
		BRANCHES: "branches",
		PHOTOGRAPHERS: "photographers",
		CUSTOMERS: "customers",
		ORDERS: "customers/manage-orders/orders",
		MANAGE_ORDERS: "customers/manage-orders",
		BOOKINGS: "bookings",
		ARCHIVE: "archive",
		SNAP_PHOTOS: "snap-photos",
		ACTIVITY_LOG: "activity-log",
	},
	IN_STORE_ADMIN: {
		IN_STORE: "in-store",
		LOGIN: "branch-admin/login",
		DASHBOARD: "dashboard",
		PHOTOGRAPHERS: "photographers",
		CUSTOMERS: "customers",
		ORDERS: "customers/manage-orders/orders",
		MANAGE_ORDERS: "customers/manage-orders",
		BOOKINGS: "bookings",
		ARCHIVE: "archive",
		SNAP_PHOTOS: "snap-photos",
		PRICE_LIST: "price-list",
		EMAIL_CONFIG: "email-config",
		ACTIVITY_LOG: "activity-log",
	},
	IN_STORE_SELLING: {
		IN_STORE_SELLING: "in-store-selling",
		// PHOTO_PASS: "photos/:branch_id/:photopass",
		PHOTO_PASS: "photos/:branch_id",
		ORDER_PLACE: "order-place",
		ARCHIVE: "archive",
		SNAP_PHOTOS: "snap-photos",
	},
};
