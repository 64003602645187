import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Page404 = () => {
	return (
		<Box
			sx={{
				height: "100vh",
				flexDirection: "column",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "center",
			}}
		>
			<Box>
				<Typography variant="h4" color="text.primary">
					404
				</Typography>
				<Typography variant="h5" color="text.primary">
					Page Not Found
				</Typography>
			</Box>
			<Typography sx={{ my: 1.25 }} color="text.secondary">
				We are sorry, the page you requested could not be found. <br /> Please go back to the homepage
			</Typography>

			<Link to="/">
				<Button sx={{ minWidth: 200 }} variant="contained" size="medium">
					Go Home
				</Button>
			</Link>
		</Box>
	);
};

export default Page404;
