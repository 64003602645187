import { Box, Container, Grid, Typography, Divider, List, ListItem, ListItemText, Avatar } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import Images from "../../../../constants/themeData.js";
import ScrollToTop from "../../../../utils/ScrollToTop.js";
import PrivacyPolicy from "../Legal/PrivacyPolicy/index.js";
import RefundPolicy from "../Legal/RefundPolicy/index.js";
import TermAndConditions from "../Legal/TermAndConditions/index.js";
import { useStyles } from "./styled.js";

const Footer = () => {
	const classes = useStyles();

	// terms and conditions dialog
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleDialogOpen = () => {
		setDialogOpen(true);
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	// privacy policy
	const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false);
	const handlePrivacyDialogOpen = () => {
		setPrivacyPolicyDialogOpen(true);
	};
	const handlePrivacyDialogClose = () => {
		setPrivacyPolicyDialogOpen(false);
	};
	// refund policy
	const [refundPolicyDialogOpen, setRefundPolicyDialogOpen] = useState(false);
	const handleRefundDialogOpen = () => {
		setRefundPolicyDialogOpen(true);
	};
	const handleRefundDialogClose = () => {
		setRefundPolicyDialogOpen(false);
	};

	const state = {
		company: {
			menuTitle: "Company",
			menuList: [
				{
					id: 1,
					list: "About Us",
					path: "about-us",
				},
				{
					id: 2,
					list: "Investment",
					path: "investment",
				},
				{
					id: 3,
					list: "Partnership",
					path: "partnership",
				},
			],
		},
		legal: {
			menuTitle: "Legal",
			menuList: [
				// {
				// 	id: 1,
				// 	list: "Terms & Conditions",
				// 	path: "Terms-&-Conditions",
				// },
				{
					id: 2,
					list: "Privacy Policy",
					path: "privacy-policy",
				},
				{
					id: 3,
					list: "Refund Policy",
					path: "refund-policy",
				},
			],
		},
		contactUs: {
			menuTitle: "Contact Us",
			menuList: [
				{
					id: 1,
					list: "Jl. Melasti No. 1 Legian, Kuta, Bali/Indonesia 80361",
				},
				{
					id: 2,
					list: "holipic@gmail.com",
					mailTo: "holipic@gmail.com",
				},
				{
					id: 3,
					list: "WA: (+62) 8113937070",
					call: "(+62) 8113937070",
				},
			],
		},
		weAccept: {
			menuTitle: "We Accept",
			menuList: [
				{
					id: 1,
					image: Images.VisaImg,
				},
				{
					id: 2,
					image: Images.MaestroImg,
				},
			],
		},
	};

	return (
		<Box pt={5}>
			<Container maxWidth="lg">
				<Grid container spacing={3}>
					{/* Company item*/}
					<Grid item xs={6} md={3}>
						<Box>
							<Typography color="text.primary" variant="h6" sx={{ fontWeight: "medium" }}>
								{state.company.menuTitle}
							</Typography>
							<List className={classes.list}>
								{state.company.menuList.map((item, _idx) => (
									<Link key={_idx} to="/">
										<ListItem>
											<ListItemText>{item.list}</ListItemText>
										</ListItem>
									</Link>
								))}
							</List>
						</Box>
					</Grid>

					{/* Legal item*/}
					<Grid item xs={6} md={3}>
						<Box>
							<Typography color="text.primary" variant="h6" sx={{ fontWeight: "medium" }}>
								{state.legal.menuTitle}
							</Typography>
							<List className={classes.list}>
								<ListItem onClick={handleDialogOpen}>
									<ListItemText>Terms & Conditions</ListItemText>
								</ListItem>

								<Link to="/">
									<ListItem onClick={handlePrivacyDialogOpen}>
										<ListItemText>Privacy Policy</ListItemText>
									</ListItem>
								</Link>
								<Link to="/">
									<ListItem onClick={handleRefundDialogOpen}>
										<ListItemText>Refund Policy</ListItemText>
									</ListItem>
								</Link>
							</List>
						</Box>
					</Grid>

					{/* Contact Us item*/}
					<Grid item xs={6} md={3}>
						<Box>
							<Typography color="text.primary" variant="h6" sx={{ fontWeight: "medium" }}>
								{state.contactUs.menuTitle}
							</Typography>
							<List className={classes.list}>
								{state.contactUs.menuList.map((item, _idx) => (
									<Link key={_idx} to="/">
										<ListItem>
											<ListItemText>{item.list}</ListItemText>
										</ListItem>
									</Link>
								))}
							</List>
						</Box>
					</Grid>

					{/* We Accept*/}
					<Grid item xs={6} md={3}>
						<Box>
							<Typography color="text.primary" variant="h6" sx={{ fontWeight: "medium" }}>
								{state.weAccept.menuTitle}
							</Typography>

							<Box sx={{ display: "flex", gap: 1 }}>
								{state.weAccept.menuList.map((item, _idx) => (
									<Avatar key={_idx} className={classes.avatar} src={item.image} alt="payment-card" />
								))}
							</Box>
						</Box>
					</Grid>
				</Grid>

				{/* scroll to top Helper */}
				<ScrollToTop />
			</Container>
			{/* sub footer */}
			<Box mt={2}>
				<Divider />
				<Container maxWidth="lg">
					<Typography color="text.secondary" sx={{ py: 2 }} variant="body1">
						&copy; {new Date().getFullYear()} Holipic App. All Rights Reserved.
					</Typography>
				</Container>
			</Box>

			{/* terms & conditions */}
			<TermAndConditions dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} />

			<PrivacyPolicy dialogOpen={privacyPolicyDialogOpen} handleDialogClose={handlePrivacyDialogClose} />

			<RefundPolicy dialogOpen={refundPolicyDialogOpen} handleDialogClose={handleRefundDialogClose} />
		</Box>
	);
};

export default Footer;
